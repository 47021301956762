<template>
  <!-- 角色管理页面 -->
  <div>
    <div class="table-layout">
      <el-row :gutter="20">
        <el-form
          ref="roleForm"
          :model="roleForm"
          label-width="90px"
          :inline="true"
        >
          <el-row class="btnRow">
            <el-col :span="5" class="elrow">
              <el-form-item
                label="唯一编码"
                prop="iditifyId"
                style="padding-left: 10px"
              >
                <el-input
                  v-model="roleForm.iditifyId"
                  size="mini"
                  clearable
                  style="width: 180px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" class="elrow">
              <el-form-item label="在线状态" prop="xsxz">
                <el-select
                  v-model="roleForm.status"
                  size="mini"
                  style="width: 200px"
                >
                  <el-option
                    v-for="item in onlineOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <search-button
              v-show="showAll"
              :showAll="showAll"
              @clearAll="clearAll"
              @exportEvent="exportEvent"
              @searchEvent="searchSenser"
              @closepop="closepop"
            ></search-button>
            <SearchButton
              v-show="!showAll"
              :showAll="showAll"
              @clearAll="clearAll"
              @exportEvent="exportEvent"
              @searchEvent="searchSenser"
              @closepop="closepop"
            ></SearchButton>
          </el-row>
        </el-form>
      </el-row>
      <!-- 分割线 -->
      <el-divider></el-divider>
      <!-- 新增按钮区域 -->
      <el-row style="margin-bottom: 10px">
        <el-col :span="1.5" class="elrow">
          <el-button
            style="width: 80px"
            type="text"
            icon="el-icon-plus"
            size="mini"
            class="editBtn"
            @click="add"
            >新增角色</el-button
          >
        </el-col>
        <el-col :span="1.5" class="elrow">
          <el-button
            style="width: 80px"
            type="text"
            icon="el-icon-refresh"
            size="mini"
            class="editBtn"
            @click="editSensor"
            >修改</el-button
          >
        </el-col>
        <el-col :span="1.5" class="elrow">
          <el-button
            style="width: 80px"
            type="text"
            class="deleteBtn"
            icon="el-icon-delete"
            size="mini"
            @click="delSensor"
            >删除</el-button
          >
        </el-col>
        <el-col :span="1.5" class="elrow">
          <el-button
            style="width: 80px"
            type="text"
            icon="el-icon-refresh"
            size="mini"
            class="editBtn"
            @click="bangdingSensor"
            >授权权限</el-button
          >
        </el-col>
      </el-row>
      <!-- 用户数据表格 -->
      <el-table
        :data="roleTable"
        style="width: 100%"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChangeNode"
        border
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="序号">
          <template slot-scope="scope">{{ scope.$index }} </template>
        </el-table-column>
        <el-table-column prop="role_id" label="角色id"> </el-table-column>
        <el-table-column prop="role" label="角色名称"> </el-table-column>
      </el-table>
    </div>
    <!-- 4、新增、修改弹窗区域-->
    <!--新增单条角色信息管理-->
    <div>
      <el-dialog
        title=""
        :append-to-body="true"
        :visible.sync="singleAddDialogVisible"
        custom-class="custom-dialog"
        width="30%"
        center
      >
        <div class="openDigl">
          <div class="item1">
            <br />
            <el-form
              label-position="left"
              label-width="100px"
              :model="roleForm"
            >
              <el-form-item label="角色id:" :required="true" v-if="false">
                <el-input
                  class="btn-text"
                  v-model="roleForm.role_id"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="角色名称:" :required="true">
                <el-input class="btn-text" v-model="roleForm.role"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  class="btn-text"
                  @click="handleClick"
                  >{{ addorEdittext }}</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 5、授权区域-->
    <!--授权弹窗需要加载用户所需能看到的菜单或者路由-->
    <div>
      <el-dialog
        title="授权权限"
        :append-to-body="true"
        :visible.sync="shouquanDialogVisiable"
        custom-class="custom-dialog"
        width="40%"
        center
      >
        <el-form>
          <el-form-item>
            <el-tree
              node-key="attribute_id"
              ref="tree"
              :data="menuList"
              :props="menuProps"
              :default-checked-keys="defaultKey"
              show-checkbox
            ></el-tree>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btn-text" @click="shouquanEvent">{{
              addorEditshoquan
            }}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getAllAuthList,
  getAllRoles,
  addNewRole,
  editRole,
  delRole,
  getAllAuthListAll,
  editRoleAuths,
  addRoleAuths,
} from "@/api/index";
import SearchButton from "./tool/button.vue";
/* 引入菜单管理api接口 */

export default {
  name: "SysMenu",
  components: {
    SearchButton,
  },
  data() {
    return {
      roleTable: [],
      /* 弹窗区域字段 */
      dialogVisible: false,
      dialogTitle: "",
      menuProps: {
        label: "attribute_name",
        children: "children",
      },
      defaultKey: [],
      // 控制菜单类型单选按钮是否可用
      typeDisabled: false,
      typeDisabled0: false,
      typeDisabled1: false,
      typeDisabled2: false,
      // 控制弹窗确定按钮是否可用
      saveBtnDisabled: false,
      singleAddDialogVisible: false,
      shouquanDialogVisiable: false,
      addorEdittext: "新增",
      addorEditshoquan: "授权",
      menuList: [
        {
          id: 1,
          label: "一级 1",
          children: [
            {
              id: 4,
              label: "二级 1-1",
              children: [
                {
                  id: 9,
                  label: "三级 1-1-1",
                },
                {
                  id: 10,
                  label: "三级 1-1-2",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          label: "一级 2",
          children: [
            {
              id: 5,
              label: "二级 2-1",
            },
            {
              id: 6,
              label: "二级 2-2",
            },
          ],
        },
        {
          id: 3,
          label: "一级 3",
          children: [
            {
              id: 7,
              label: "二级 3-1",
            },
            {
              id: 8,
              label: "二级 3-2",
            },
          ],
        },
      ],
      selctionNodeRows: [],
      nodeids: [],
      showAll: true,
      //查询条件form
      roleForm: {
        role_id: "",
        role: "",
      },
      onlineOptions: [
        { label: "userAdmin", value: "1" },
        { label: "admin", value: "2" },
        { label: "user", value: "3" },
      ],
      menuRules: {
        parentName: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "菜单类型不能为空",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
        ],
        icon: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        sortValue: [
          {
            required: true,
            message: "排序",
            trigger: "blur",
          },
        ],
        perms: [
          {
            required: true,
            message: "请输入权限字符",
            trigger: "blur",
          },
        ],
        component: [
          {
            required: true,
            message: "请输入组件地址",
            trigger: "blur",
          },
        ],
        path: [
          {
            required: true,
            message: "请输入路由地址",
            trigger: "blur",
          },
        ],
      },
      typeOptions: [
        {
          label: "目录",
          value: 0,
        },
        {
          label: "菜单",
          value: 1,
        },
        {
          label: "按钮",
          value: 2,
        },
      ],
    };
  },
  methods: {
    /* 获取角色 */
    getRoleList() {
       getAllRoles().then(res=>{
      if (res.code == 200) {
        this.roleTable = res.data;
      } else {
        this.$message.error(res.error);
      }
       }).catch(err => {
        console.log(err)
       })
    },
    add() {
      // 新增传感器弹窗信息
      this.roleForm = {};
      this.addorEdittext = "新增";
      this.singleAddDialogVisible = true;
    },
    // 单条节点管理
    handleSelectionChangeNode(selection) {
      this.selctionNodeRows = selection;
      this.nodeids = selection.map((item) => item.id); // 需要根据数据情况调整id名称
    },
    // 新增传感器信息
    addSensor() {
      addNewRole(this.roleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功！");
          this.getRoleList();
        } else {
          this.$message.error(res.error);
        }
        this.singleAddDialogVisible = false;
      });
    },
    // 修改站点信息弹窗
    editSensor() {
      if (this.nodeids.length !== 1) {
        this.$message.warning("请选择一条数据进行修改操作！");
      } else {
        console.log(this.selctionNodeRows);
        this.roleForm.role_id = this.selctionNodeRows[0].role_id;
        this.roleForm.role = this.selctionNodeRows[0].role;
        this.singleAddDialogVisible = true;
        this.addorEdittext = "修改";
      }
    },
    // 批量删除或删除单个传感器
    delSensor() {
      if (this.nodeids.length == 0) {
        this.$message.warning("请至少选择一条数据进行删除操作！");
      } else {
        let params = []
        this.selctionNodeRows.forEach(v=>{
          params.push({
            role_id: v.role_id
        })
        })
        delRole({roles: params})
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("删除成功");
              // 请求表格数据
              this.getRoleList();
            } else {
              this.$message.error(res.error);
            }
          })
          .catch(() => {});
      }
    },
    handleClick() {
      if (this.addorEdittext == "新增") {
        this.addSensor();
      } else {
        this.relationEdit();
      }
    },
    // 修改角色
    relationEdit() {
      // 使用链接put 修改
      let params = {
        role_id: this.roleForm.role_id,
        role: this.roleForm.role,
      };
      editRole(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功！");
          // 关闭弹窗 加载列表
          this.singleAddDialogVisible = false;
          this.getRoleList();
        }
      });
    },
    closepop() {
      this.showAll = !this.showAll;
    },
    // 绑定传感器和设备
    bangdingSensor() {
      // 打开弹窗 绑定的弹窗加载设备列表，选中一个设备绑定即可
      // 加载所有权限列表
      getAllAuthListAll().then(res=>{
        this.menuList = res.data
        this.menuList = this.formatTree(this.menuList, 0);
          // this.$store.dispatch("setPermissions", this.menuList);
        })
      if (this.nodeids.length !== 1) {
        this.$message.warning("请选择一条数据进行修改操作！");
      } else {
        // 判断一下，如果有过授权的话，那么就改成修改授权；如果没有授权的话，还是授权；通过查询授权结果得到的结果不为空
        getAllAuthList(this.selctionNodeRows[0].role_id)
          .then((res) => {
        if (res.code == 200) {
          if (res.data.length > 0) {
            res.data.forEach(v=>{
              this.defaultKey.push(v.attribute_id)
            })
            this.shouquanDialogVisiable = true;
            this.addorEditshoquan = "修改授权";
            // 如果有可能的话，这块需要把授权赋值过去，以便用户修改
          } else {
            this.shouquanDialogVisiable = true;
            this.addorEditshoquan = "授权";
            // 这里就是加载所有权限列表，以供用户在列表中单选
          }
        }
        })
        .catch((err) => {
          console.log(err);
        });
      }
    },
    // 后端反的格式转化成数组嵌套
    formatTree(arr, attribute_id) {
      let newArr = [];
      arr.forEach((item) => {
        if (item.parent_id == attribute_id) {
          item.children = this.formatTree(arr, item.attribute_id);
          newArr.push({
            ...item,
          });
        }
      });
      return newArr;
    },
    // 授权事件
    shouquanEvent() {
        console.log(this.$refs.tree.getCheckedNodes())
      // 授权事件
      if (this.addorEditshoquan == "授权") {
        this.addOneAuth();
      } else {
        this.editOneAuth();
      }
    },
    // 新增授权
    addOneAuth() {
       let params = {
        role_id: this.selctionNodeRows[0].role_id,
        attributes: []
      }
      this.$refs.tree.getCheckedNodes().forEach(v=>{
         params.attributes.push(v.attribute_id)
      })
      addRoleAuths(params).then(res=>{
        if(res.code == 200){
          this.$message.success("授权成功！")
        } else {
          this.$message.error("授权失败！原因是"+ res.error)
        }
        this.shouquanDialogVisiable = false
        this.getRoleList()
      })
    },
    editOneAuth() {
      let params = {
        role_id: this.selctionNodeRows[0].role_id,
        attributes: []
      }
      this.$refs.tree.getCheckedNodes().forEach(v=>{
         params.attributes.push(v.attribute_id)
      })
      addRoleAuths(params).then(res=>{
        if(res.code == 200){
          this.$message.success("授权成功！")
        } else {
          this.$message.error("授权失败！原因是"+ res.error)
        }
        this.shouquanDialogVisiable = false
        this.getRoleList()
      })
    },
    searchSenser() {
      console.log("您进入了父组件，点击了查询之后可以得到：" + this.roleForm);
    },
    // 重置
    clearAll() {},
    exportEvent() {
      this.$message.success("导出按钮");
    },
  },
  //当页面加载时获取数据
  created() {
    // 获取所有角色
    this.getRoleList();
  },
};
</script>

<style lang="scss" scoped>
@import "./table.scss";
.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 10px 0;
}
.dialog-item {
  background-color: rgba(27, 42, 65, 0.8);
}
::v-deep .custom-dialog .el-dialog__body {
  height: auto;
  overflow: hidden;
  background-color: #062b7cc2;
  border: 1px solid #409eff;
  color: aliceblue;
  overflow: auto;
}

.openDig {
  display: flex;
  background-color: #285ed4c2;
}
.custom-dialog {
  ::v-deep .el-card__body,
  .el-main {
    padding: 5px;
  }

  ::v-deep .el-image__inner {
    margin-top: 20%;
  }

  .dga_data {
    width: 100%;
  }
  ::v-deep .el-form-item__label {
    font-size: 18px;
    color: #041634;
    line-height: 40px;
  }

  ::v-deep .el-dialog__header {
    padding: 0px !important;
    background-color: #041634 !important;
  }

  ::v-deep .el-dialog__body {
    background-color: #041634;
  }

  ::v-deep .el-dialog__footer {
    background-color: #041634;
  }

  ::-webkit-scrollbar {
    width: 1px;
  }
  //滚动条滑块
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgb(3, 33, 92);
    transition: 0.3s ease-in-out;
  }
}
::v-deep .el-tree {
  position: relative;
  cursor: pointer;
  background: transparent;
  color: #fff;
}
::v-deep .el-tree-node__content {
  &:hover {
    background: #6f9ec9;
  }
}
::v-deep .el-tree-node.is-current > .el-tree-node__content {
  background-color: rgb(20, 107, 107) !important;
}
</style>